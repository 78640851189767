.lineChartContainer {
    min-height: 420px;
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    background-color: #fff;

    @media only screen and (max-width: 812px) {
        padding: 0 30px;
    }

    .trendsLeftContainer {
        display: flex;
        min-width: 350px;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 2rem;
        padding-left: 1.5rem;
        border-right: 1px solid #DFE2EA;
        overflow-y: auto;

        @media only screen and (max-width: 812px) {
            display: none;
        }
    }

    .trendsRightContainer {
        width: calc(100% - 300px - 2rem);
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        padding-top: 1.2rem;
        margin-right: 20px;

        &.chartCard {
            width: 100%;
            box-sizing: border-box;
            // padding: 0;
            overflow: hidden;
        }

        @media only screen and (max-width: 812px) {
            padding-left: 0;
            width: 100%;
        }

        .trendsChartTopContainer {
            display: flex;
            justify-content: space-between;
            padding-right: 0.5rem;
            padding-bottom: 1rem;
            flex-wrap: wrap;

            .withQText {
                max-width: 580px;
                min-width: 300px;

                >span {
                    display: block;
                    position: relative;
                    border: none;
                    box-shadow: none;
                    width: 100%;
                    text-align: right;
                    z-index: 0;
                    font-size: 11px ;
                    display: flex;
                    height: 50px;
                    justify-content: center;
                    flex-direction: column;
                    .warningText{
                        color: #E51E00;
                        font-size: 11px;
                        margin-bottom: 3px;
                    }
                }
            }

            .selectContainer {
                display: flex;
            }

            .inputBox {
                min-width: 180px;
                height: 50px;
                box-sizing: border-box;
                border-radius: 2.5rem;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
                color: #000000;
                font-family: "azo-sans-web", sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                padding: 14px 50px 20px 34px;
                margin-right: 20px;
                border: 1px solid #dfe2ea;

                img.ddArrow {
                    top: 18px !important;
                    right: 20px !important;
                    position: absolute;
                }

                .selectListContainer {
                    max-height: 247px;
                    top: 106%;
                    width: auto;
                    
                    >.selectItem {
                        font-size: 13px;
                        padding: 3px 15px;
                    }
                }
            }

            .sizeContainer {
                display: flex;
                align-items: center;

                .sizeIcon {
                    width: 2.125rem;
                    height: 2.125rem;
                    border-radius: 1.0625rem;
                }

                .sizeTitleText {
                    padding-left: 0.5rem;
                    color: #DFE2EA;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 1.125rem;
                    font-weight: 400;
                    line-height: 1.125rem;
                    text-align: right;
                }

                .sizeText {
                    color: #000000;
                    padding-left: 0.25rem;
                    font-family: "azo-sans-web", sans-serif;
                    font-size: 1.125rem;
                    font-weight: bold;
                    line-height: 1.125rem;
                    text-align: right;
                }
            }
        }

        .bar:hover {
            opacity: 0.5;
        }
    }
}

.trendsTooltip {
    position: absolute;
    display: none;
    box-sizing: border-box;
    padding: 10px 8px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    z-index: 10;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    >div {
        text-align: center;
        // width: 160px;

        &.brand {
            width: 100%;
            color: #000000;
            font-family: 'Silka-SemiBold', sans-serif;
            font-size: 13px;
            line-height: 20px;
            padding-bottom: 8px;
            border-bottom: 1px solid #DFE2EA;

            &.single {
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        &.value {
            color: #000000;
            font-family: "azo-sans-web", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 2px;
        }

        &.label {
            color: #71767D;
            font-family: 'Silka-Bold', sans-serif;
            font-size: 0.775rem;
            letter-spacing: 0.125rem;
            line-height: 18px;
            padding: 10px 0 0;
        }

        &.percentage {
            font-family: "azo-sans-web", sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            margin-top: 2px;
        }

        &.sampleSize {
            font-family: "azo-sans-web", sans-serif;
            font-size: 12px !important;
            font-weight: 400;
            line-height: 18px;
            margin-top: 2px;
        }

        &.date {
            font-family: "azo-sans-web", sans-serif;
            font-size: 13px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 2px;
        }
    }
}

.node:hover {
    opacity: 0.5;
}