.brandItem {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    padding: 13px 16px;
    position: relative;

    .brandItemRightContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        cursor: pointer;
        top: 17px;

        .showOrHideIconContainer {
            .showOrHideIcon {
                display: none;
            }
        }

        &.noHidden {
            cursor: default;

            &:hover {
                opacity: 1;

                .showOrHideIcon {
                    display: none !important;
                }
            }
        }

        &.brandHidden {
            opacity: 0.5;

            .showOrHideIconContainer {
                position: absolute;
                right: 0;
                width: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 17px;

                .showOrHideIcon {
                    display: flex;
                    width: 1.625rem;
                    height: 1rem;
                }

                &.rightRem {
                    right: 1.5rem;
                }
            }
        }

        &:hover:not(:has(.segmentWarning:hover)) {
            .showOrHideIconContainer {
                opacity: 0.5;
                position: absolute;
                right: 0;
                width: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 17px;

                .showOrHideIcon {
                    display: flex;
                    width: 1.625rem;
                    height: 1rem;
                }

                &.rightRem {
                    right: 1.5rem;
                }
            }
        }

        .brandTitle {
            width: 100%;

            .brandLogoIcon,
            .brandDefaultLogo {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 1.5625rem;
                object-fit: cover;
                border: 2px solid #FFFFFF;
                border-radius: 1.6875rem;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
                margin-right: 0.75rem;
            }

            .brandName {
                width: calc(100% - 3.875rem);
                color: #000000;
                font-family: "Silka-SemiBold", sans-serif;
                font-size: 13px;
                line-height: 18px;
                white-space: normal;
            }

        }
    }

    .legend {
        height: 8px;
        width: 8px;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
        }
    }

    .colorButtonContainer {
        padding: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .tooltipText {
        visibility: visible;
        min-width: 15px;
        background-color: #edeff5;
        color: #4c4d4e;
        text-align: center;
        border-radius: 6px;
        padding: 6px 9px;
        position: absolute;
        z-index: 1;
        font-size: 12px;
        white-space: nowrap;
        letter-spacing: 0;
        font-weight: 400;
        background-color: #FFFFFF;
        box-shadow: 0 5px 3px -2px rgba(0, 0, 0, .0196078431), 0 3px 2px -2px rgba(0, 0, 0, .0588235294) !important;
        margin: -60px 0px 0px -10px;
        border: 1px solid #e4e7ec;
    }

    .operateContainer {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        padding: 0px 15px;

        >div {
            cursor: pointer;
            color: #71767D;
            font-family: "azo-sans-web", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            margin: 5px;
        }
    }
}

.show-color-picker {
    position: fixed;
    z-index: 9999;
    white-space: nowrap;
}

.show-color-picker-conatiner {
    position: relative;
    white-space: nowrap;
}

.color-picker {
    position: absolute;
    z-index: 9999;

    input[id*='rc-editable-input-']+label,
    svg {
        display: none !important;
    }

    input[id*='rc-editable-input-'] {
        width: 67px !important;
        height: 30px !important;
        font-size: 11px !important;
        border: 1px solid #fff !important;
        text-align: center !important;
        border-radius: 3px !important;
    }

    left: 3px !important;
    top: -190px !important;
}

div.arrow-right {
    width: 180px !important;
    background: rgb(255, 255, 255) !important;
    border-radius: 5px !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    box-sizing: initial !important;
    font-family: Menlo !important;
    border: 1px solid #e4e7ec;
}

.colorSaveButton {
    position: absolute;
    bottom: 12px;
    height: 32px;
    background: rgb(36, 31, 31);
    border-radius: 3px;
    z-index: 9;
    left: 51%;
    width: 72.6px;
    color: white;
    font-size: 9px;
    font-family: "azo-sans-web", sans-serif;
    border: none;
    top: 143px;
}