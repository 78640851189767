@import "~@fortawesome/fontawesome-free/css/all.css";

.tooltip-container {
  * {
    font-family: "Roboto", sans-serif;
  }
  position: fixed;
  display: inline-block;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  .tooltip-text {
    background-color: #ffffff;
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 0;
    transition: opacity 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 99.9%;
    right: 14px;
    border-width: 5px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
  }
  .tooltip-message-button {
    min-width: 18px !important;
    border-radius: 50% !important;
    padding: 10px !important;
    background-color: #04927a !important;
    color: white !important;

    svg {
      width: 18px;
      height: 18px;
    }
  }
  .chat-container {
    display: flex;
    width: 350px;
    height: 500px;
    flex-direction: column;
    -webkit-box-shadow: -4px 13px 36px -11px rgba(128, 128, 128, 0.82);
    -moz-box-shadow: -4px 13px 36px -11px rgba(128, 128, 128, 0.82);
    box-shadow: -4px 13px 36px -11px rgba(128, 128, 128, 0.82);
    box-sizing: border-box;
    overflow: hidden;

    // Default styles for the chat container
    .card-header {
      background-color: #04927a;
      align-items: center;
      box-sizing: border-box;
      padding: 15px 20px;
      display: flex;

      .card-title {
        display: flex;
      
        flex: 1 1 auto;
        gap: 8px;
        font-size: 16px;
        span{
          display: inline-flex;
          align-items: center;
          vertical-align: middle;
        }

        .beta-tag {
          display: inline-flex;
          align-items: center;
          vertical-align: middle;
          background-color: #292D33;
          color: #ffffff;
          font-size: 0.5em;
          font-weight: 500;
          padding: 0.8em 1.5em;
          border-radius: 12px;
          margin-left: 0.25em;
          font-family: "Inter", sans-serif;
          line-height: 1;
          box-sizing: border-box;
        }
      }
      
      .card-title > * {
        margin: 0;
      }
      
      

      .close-icon {
        display: flex;
        border-radius: 50%;
        cursor: pointer;
        padding: 5px;
        width: fit-content;
        height: fit-content;
        background: #e3fffa1f;
        flex: 0 0 auto;
        text-align: center;
        vertical-align: middle;
        margin-left: 5px;
      }

      .close-icon > svg {
        width: 18px;
        height: 18px;
        color: white;
        text-align: center;
        vertical-align: middle;
      }

      span {
        line-height: 24px;
        color: #ffffffe2;
      }

      span:first-child {
        font-family: "Roboto", sans-serif;
        font-size: 1rem;
        font-weight: 500;
        font-style: normal;
      }

      span:last-child {
        font-size: 0.8rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
    }

    &.expanded {
      width: 650px;
      height: 800px;
      max-height: 80vh;

      .card-content {
        flex: 1;
        overflow-y: auto;
      }

      .card-footer {
        padding: 10px;
        min-height: 50px;
        max-height: 100px;
      }
    }

    .card-content {
      flex: 1;
      height: auto;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      scroll-behavior: smooth;
      scrollbar-width: thin;
      padding: 0 !important;
      box-sizing: border-box;

      .msg-container {
        width: 100%;
        max-height: 100%;
        text-align: start;
        list-style: none;
        padding: 10px 15px;
        margin: 0;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        scrollbar-width: thin;

        .align-left:hover .messageFeedbackicons {
          display: flex;
          flex-wrap: wrap;
          transition: opacity 0.3s ease, all 0.3s ease;
        }
        .cont {
          font-family: "Roboto", sans-serif;
          font-weight: 350;
          font-style: normal;
          font-size: 0.8rem;
          overflow-y: hidden;
          ul.ks-cboxtags {
            list-style: none;
            padding: 5px 0px;
          }
          ul.ks-cboxtags li {
            display: inline;
          }
          ul.ks-cboxtags li label {
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.9);
            border: 1px solid #04927a;
            color: #04927a;
            border-radius: 25px;
            white-space: nowrap;
            margin: 3px 3px;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
            transition: all 0.2s;
            padding: 3px 10px;
          }

          ul.ks-cboxtags li label {
            cursor: pointer;
          }

          ul.ks-cboxtags li label::before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 10px;
            padding: 2px 6px 2px 2px;
            content: "\f067";
            transition: transform 0.3s ease-in-out;
          }

          ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
            content: "\f00c";
            transform: rotate(-360deg);
            transition: transform 0.3s ease-in-out;
          }

          ul.ks-cboxtags li input[type="checkbox"]:checked + label {
            border: 0.5px solid #04927a;
            background-color: #04927a;
            color: #fff;
            transition: all 0.2s;
          }

          ul.ks-cboxtags li input[type="checkbox"] {
            display: absolute;
          }
          ul.ks-cboxtags li input[type="checkbox"] {
            position: absolute;
            opacity: 0;
          }
          ul.ks-cboxtags li input[type="checkbox"]:focus + label {
            border: 0.5px solid #04927a;
          }
        }
      }
    }

    .card-footer {
      width: 100%;
      padding: 10px;
      min-height: 50px;
      max-height: 100px;
      display: flex;
      box-sizing: border-box;
      align-items: flex-start;
      border-top: 1px solid #dededee2;
      position: relative;
      overflow: hidden;
      gap: 10px;
      background-color: white;

      button {
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #292D33;
        color: white;
        border: none;
        cursor: pointer;
        margin-top: 4px;
        margin-bottom: 4px;
        align-self: flex-end;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      textarea {
        width: 100%;
        flex: 1;
        resize: none;
        overflow-y: auto;
        padding: 10px;
        font-family: "Roboto", sans-serif;
        font-size: 0.9rem;
        line-height: 1.5;
        box-sizing: border-box;
        background-clip: padding-box;
        min-height: 30px;
        max-height: 80px;
        margin: 0;
        display: block;
        background-color: white;
        border: none;
      }

      textarea::-webkit-scrollbar {
        display: none;
      }

      textarea:focus-visible {
        outline: none;
      }
    }

    .msg-text {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      opacity: 0;
      height: 0;
      transform: translateY(10px);
      transition: opacity 0.3s ease-in, height 0.3s ease-in;

      p {
        margin-bottom: 2px;
        padding-top: 0;
        font-size: 0.8rem;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        color: rgb(31, 30, 30);
      }

      &:last-child {
        padding-bottom: 10px;
      }
    }
    .msg-text.show {
      opacity: 1;
      height: auto;
      transform: translateY(0);
    }
    .msg-text.hide {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    .feedback-container {
      max-width: 80%;

      flex-direction: column;
      color: black;
      margin: 10px auto 5px auto;
      font-size: 0.8rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      line-height: 22px;
      font-style: normal;
      display: flex;
      flex-wrap: wrap;
      background: rgb(252, 252, 252);
      color: black;
      padding: 3px 15px;
      border-radius: 20px;
      border: 0.5px solid rgb(231, 231, 231);

      text .feedbackcontent {
        flex: 0 1 auto;
        margin: 5px;
        display: block;
      }

      .feedbackicons {
        flex: 0 1 auto;
      }

      svg {
        width: 15px;
        padding: 3px;
        margin-left: 3px;
        vertical-align: middle;
      }
    }

    .You,
    .Harris {
      max-width: 80%;
      min-height: 18px;
      width: fit-content;
      border-radius: 10px;
      font-size: 0.8rem;
      line-height: 22px;
      padding: 6px 10px;
      font-family: "Roboto", sans-serif;
      font-weight: 350;
      font-style: normal;
      margin-top: 2px;
      word-wrap: break-word;
      word-spacing: normal;
      overflow-wrap: break-word;
    }

    .messageFeedbackicons {
      color: black;
      font-size: 0.8rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      line-height: 22px;
      font-style: normal;
      color: black;
      display: none;

      svg {
        width: 15px;
        padding: 3px;
        vertical-align: middle;
        font-size: 12px;
      }

      svg:nth-child(1):hover {
        color: #04927a;
      }

      svg:nth-child(2):hover {
        color: #d84646;
      }
    }

    .You {
      --parent-color: #ffffffe2;
      background-color: #04927a;
      color: var(--parent-color);
      border-top-right-radius: 0;
      font-weight: normal;
    }

    .Harris {
      background-color: rgb(240, 249, 255);
      --parent-color: rgb(207, 206, 206);
      color: black;
      border-top-left-radius: 0;
    }

    .align-right {
      align-items: flex-end;
    }

    .align-left {
      align-items: flex-start;
    }

    /* .card-actions {
    padding: 0;
    border: 0;
    border-top: 1px solid #dededee2;
  
    div,
    textarea,
    fieldset {
      border: 0;
      border-width: 0;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 0.83rem;
    }
  
    .submit-btn {
      padding: 8px;
      border-radius: 50%;
      min-width: 18px;
      background-color: rgb(64, 64, 64);
  
      svg {
        width: 15px;
        height: 15px;
      }
    }
  } */

    @keyframes typingLoader {
      0%,
      60%,
      100% {
        transform: translateY(0);
      }

      30% {
        transform: translateY(-4px);
      }
    }

    .typing-loader {
      display: flex;
      justify-content: center;
      align-items: center;

      .dot {
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background-color: var(--parent-color);
        border-radius: 50%;
        animation: typingLoader 1.2s infinite ease-in-out both;
      }

      .dot:nth-child(1) {
        animation-delay: -0.32s;
      }

      .dot:nth-child(2) {
        animation-delay: -0.16s;
      }
    }
    @keyframes typingLoader {
      0%,
      80%,
      100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1);
      }
    }
  }
  .multi-select-checkbox-container {
    width: 100%;
    max-height: 0;
    padding: 3px;
    box-sizing: border-box;
    margin: 0px;
    text-align: start;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    font-size: 0.8rem;
    overflow-x: scroll;
    display: flex;
    transition: max-height 0.3s ease, opacity 0.3s ease;

    ul.ks-cboxtags {
      list-style: none;
      padding: 0px 0px;
      margin: 0px;
      display: flex;
    }
    ul.ks-cboxtags li {
      display: inline-flex;
    }
    ul.ks-cboxtags li label {
      display: inline-block;
      border: 0.5px solid #04927a;
      background-color: #04927a;
      color: #fff;
      border-radius: 25px;
      white-space: nowrap;
      margin: 3px 3px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      padding: 3px 10px;
    }

    ul.ks-cboxtags li label {
      cursor: pointer;
    }
    ul.ks-cboxtags li input[type="checkbox"] {
      display: absolute;
    }
    ul.ks-cboxtags li input[type="checkbox"] {
      position: absolute;
      opacity: 0;
    }
  }
  .multi-select-checkbox-container.visible {
    max-height: 50px;
    opacity: 1;
  }

  .multi-select-checkbox-container.hidden {
    max-height: 0;
    padding: 0px;
    opacity: 0;
  }

  .options-single-click-button {
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    font-size: 0.8rem;
    overflow-y: hidden;
    ul.ks-cboxtags {
      list-style: none;
      padding: 5px 0px;
    }
    ul.ks-cboxtags li {
      display: inline;
    }
    ul.ks-cboxtags li label {
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid #04927a;
      color: #04927a;
      border-radius: 25px;
      white-space: nowrap;
      margin: 3px 3px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      padding: 3px 10px;
    }

    ul.ks-cboxtags li label {
      cursor: pointer;
    }
    ul.ks-cboxtags li label:hover {
      border: 0.5px solid #04927a;
      background-color: #04927a;
      color: #fff;
      transition: all 0.2s;
    }

    ul.ks-cboxtags li input[type="checkbox"] {
      display: absolute;
    }
    ul.ks-cboxtags li input[type="checkbox"] {
      position: absolute;
      opacity: 0;
    }
    ul.ks-cboxtags li input[type="checkbox"]:focus + label {
      border: 0.5px solid #04927a;
    }
  }
  .listConatiner {
    background: #fff;
    padding: 0px;

    .listViewAction {
      align-items: center;
      display: flex;
      font-size: 12px;

      font-weight: 600;
      color: #344054;
      padding: 10px 12px;
      gap: 8px;
      cursor: pointer;
      margin: 3px;
      border: 1px solid rgb(249, 249, 249);
      .viewsActionIcons {
        font-size: 20px;
      }
      svg {
        width: 15px;
        height: 15px;
      }

      &:hover {
        background-color: #098f6c;
        color: white;
      }
    }
  }
}
