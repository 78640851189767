.popover-container {
    position: relative;
    display: inline-block;
    
  }
  
  .popover-trigger {
    border:none;
    background:transparent;
    cursor: pointer;
    padding:0px;
    margin:0px;
    align-items: center;
    vertical-align: middle;
  }
  
  .popover-content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    white-space: nowrap;
  }