.Toastify__toast {
    width: 400px;
    max-height: 108px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
    display: flex;
    justify-content: center;
}

.Toastify__toast-body {
    gap: 3px;
    align-items: normal;
}

.Toastify__toast-icon {
    width: auto;
}

.fileNameHeader {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: 0%;
    color: #6941C6;
}

.descriptionHeader {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0%;
    color: #344054;
}

.exportIcon {
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid #EAECF0;
    border-radius: 5px;
    border-width: 1px;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.toasterHeader {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    height: 20px;
    color: #101828;
}

.Toastify__toast-container {
    position: fixed !important;
    bottom: 20px;
    left: 20px;
    width: 400px;
    max-height: 80vh;
    overflow: visible !important;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}

.Toastify__toast {
    position: relative !important;
    pointer-events: auto;
    height: 108px;
    z-index: 1;
    opacity: 0.9;
    gap: 10px;
    border-radius: 6px;
}

.Toastify__toast:nth-child(1) {
    width: 360px;
    margin-left: 20px;
}

.Toastify__toast:nth-child(2) {
    width: 370px;
    margin-left: 15px;
}

.Toastify__toast:nth-child(3) {
    width: 380px;
    margin-left: 10px;
}

.Toastify__toast:nth-child(4) {
    width: 390px;
    margin-left: 5px;
}

.Toastify__toast:last-child {
    width: 400px;
    margin-left: 0px;
}

.Toastify__toast:not(:last-child) {
    margin-bottom: -94px;
}

.Toastify__toast-container:hover .Toastify__toast:not(:last-child) {
    margin-bottom: 5px;
}

.Toastify__toast:last-child {
    opacity: 1;
    z-index: 10;
}

.Toastify__toast-container:hover .Toastify__toast {
    width: 400px !important;
    margin-left: 0px !important;
    opacity: 1 !important;
    --toast-separation: 20px;
    transition: margin-bottom 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.Toastify__toast-container:hover {
    pointer-events: auto;
    display: flex;
}


.Toastify__toast-container:hover .Toastify__toast {
     transform: translateY(0px); /* Reset position */
}