.reportsPageContainer {
    height: 100vh !important;
    overflow: hidden;
    .headerContainer {
        height: auto !important;
    }
}

.viewsContainer {
    background-color: rgb(245, 247, 250);

    .emptyImageContainer{
        height: 480px;
        width: 480px;
    }

    .emptyCardContainer {
        position: relative;
        margin: auto;
        background-image: url('../../assets/icons/empty_views_logo.svg');

        .emptyCard {
            height: 256px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: 39%;
        }
        .emptyFileImageConatiner {
            height: 155px;
            width: 100px;
            background: linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
        }

        .emptyContentContainer {
            width: 352px;
            height: 150px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-family: "Inter", sans-serif;

            .header {
                color: rgb(16, 24, 40);
                height: 28px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }

            .subHeader {
                height: 40px;
                font-size: 14px;
                line-height: 20px;
                color: rgb(71, 84, 103);
            }
        }

        .form-button-actions {
            align-items: center;
            display: flex;
            justify-content: center;

            .saveButton {
                width: 120px;
                height: 40px;
            }
        }
    }

    .viewCard:hover .viewMoreIcon {
        display: block;
        width: 25px;
        height: 25px;
    }

    .viewCard {
        max-width: 282px;
        min-height: 173px;
        width: 100%;
        border-radius: 12px;
        border: 1px solid #E4E7EC;
        box-shadow: 0px 5px 3px -2px #00000005, 0px 3px 2px -2px #0000000F;
        font-family: "Inter",serif;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .moreOptionContainer {
            text-align: end;
            width: 95%;
            opacity: 0;
            padding: 4px 10px;

            .moreOptionIcon {
                width: 22px;
                padding: 0px 10px;
            }

            .moreOptionList {
                padding: 5px 2px;
                margin: 0px;
                font-size: 13px;
                list-style: none;

                li {
                    text-align: left;
                    padding: 3px 8px;
                    display: flex;
                    gap: 7px;
                    align-items: center;

                    img {
                        height: 16px;
                        width: 16px;
                    }
                }
            }

            .tippy-content {
                padding: 0px;
            }
        }

        &:hover>.moreOptionContainer {
            opacity: 1;
        }
    }

    .viewContentContainer {
        display: flex;
        max-width: 282px;
        padding: 16px;
        gap: 65px;
        background: #fff;
        border-radius: 12px 12px 0px 0px;
        .reportActionContainer{
            height: 18px;
            gap: 5px;
        }
    }

    .cardContentContainer {
        max-width: 128px;
        min-height: 89px;
        gap: 20px;
        display: flex;
        flex-direction: column;

        .viewHeaderContainer {
            width: 170px;
            min-height: 37px;
            font-family: "Inter",serif;
            gap: 12px;

            .header {
                height: 17px;
                font-weight: 600;
                font-size: 15px;
                line-height: 16.8px;
                letter-spacing: 0px;
                color: #1d2739;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                letter-spacing: -0.5px;
            }

            .subHeader {
                height: 20px;
                color: #98a2b3;
                line-height: 22.5px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 11px;
                font-weight: 500;
                white-space: nowrap;
            }

        }

        .screensIconsContainer {
            height: 32px;
            width: 104px;
            display: flex;
            position: relative;

            .avatarIconContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                border-radius: 32px;
                position: absolute;
                cursor: pointer;

                .avatarIcon {
                    width: 16px;
                    height: 16px;
                    color: #fff;
                }

                .avatarIconNumber {
                    font-size: 14px;
                    font-family: 'Inter', sans-serif;
                    font-weight: 600;
                    line-height: 20px;
                    color: #475467;
                }
            }
        }
    }

    .viewCategoryImg {
        width: 330px;
        max-width: calc(100% - 40px);
        border-radius: 4px;
        background-color: #EFF1F6;
        position: relative;
    }

    ;

    .viewCategoryImage1 {
        width: 100%;
        height: 2px;
        top: 98px;
        left: 0;
        background-color: #FFF;
        position: absolute;
    }

    ;

    .viewCategoryImage2 {
        width: 2px;
        height: 200px;
        top: 0px;
        left: calc(50% - 1px);
        background-color: #FFF;
        position: relative;
    }

    ;

    .viewHeaderContent {
        display: flex;
        padding-left: 15px;
        flex-direction: column;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #71767D;
        width: 90%;
    }

    ;

    .viewContent {
        display: flex;
        padding-left: 15px;
        flex-direction: column;
        gap: 10px;
        font-size: 13px;
        font-weight: 500;
        color: #71767D;
        width: 90%;
    }

    .viewLinkContainer {
        height: 52px;
        max-width: 282px;
        border-top: 1px solid #f0f2f5;
        background-color: #f9fafb;
        display: flex;
        align-items: center;
        border-radius: 0px 0px 12px 12px;
        cursor: pointer;

        .viewLinkButton {
            height: 20px;
            justify-content: space-between;
            gap: 10px;
            display: flex;
            padding: 16px;
            width: 250px;
            color: #cc400c;

            .viewLinkContent {
                font-size: 12px;
                font-weight: 500;
                font-family: "Silka-SemiBold", sans-serif;
            }

            .viewLinkIcon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .listContainer {
        min-height: 20px;
        width: 212px;
        border: 1px solid #eaecf0 ;
        border-radius: 12px;
        box-shadow:0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
        background: #fff;
        position: relative;
        z-index: 10;
        right: 190px;

    }

    .viewsActions {
        position: absolute;
        background-color: #fff;
        box-shadow: 0 12px 20px 0 #959698;
        margin-right: 10px;
        position: absolute;
        margin-left: 130px;
        padding: 5px;
        margin-top: 70px;
        cursor: pointer;
    }

    .viewTextAlign {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #71767D;
        padding: 5px;
    }

    ;

    .textContainer {
        font-size: 17px;
        color: #000000;
        font-weight: 600;
    }

    .viewLogoContainer {
        width: 35px;
        height: 30px;
        border-radius: 8px;
        border: 1px solid rgb(221 222 225);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ;

    .viewIcon {
        width: 18px;
        height: 16px;
    }

    .viewMoreIcon {
        display: none;
    }

    .viewImage {
        width: 15px;
        height: 13px;
    }

    ;

    .viewCardHeaderContainer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    ;

    .viewTitle {
        color: #383636;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        width: 100%;
        font-family: "Silka-SemiBold", sans-serif;
        padding: 20px 0px 5px 0px;
    }

    ;

    .viewLink {
        color: #db5c49;
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        padding: 5px 0px;
    }

    ;

    .viewLinkButtonConatiner {
        display: flex;
        width: 94%;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        color: #db5c49;
        cursor: pointer;
        box-shadow: 0px 1.96774px 3.93548px 0px rgba(0, 0, 0, 0.10), 0px 0px 3.93548px 0px rgba(0, 0, 0, 0.10);
    }

    ;

    .viewSecondaryTitle {
        display: flex;
        padding-bottom: 15px;
        align-items: flex-start;
        font-size: 12px;
        font-weight: 500;
        color: #71767D;
        width: 100%;
    }

    ;

    .viewsLogosContainer {
        height: 45px;
        display: flex;
        position: relative;
        align-items: center;
    }

    ;

    .viewLogoItem {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: absolute;
    }

    ;

    .viewsSelectedItemsList {
        box-sizing: border-box;
        height: 25px;
        width: 25px;
        border: 2px solid #EFF1F6;
        border-radius: 50%;
        font-family: 'Silka-SemiBold', sans-serif;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    ;

    .viewsSelectedItemsList:hover .tooltiptext {
        visibility: visible;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: normal;
    }

    .viewsSelectedItemsList .tooltiptext {
        visibility: hidden;
        border-radius: 6px;
        padding: 5px 9px;
        position: absolute;
        z-index: 1;
        left: 15%;
        top: 100%;
        font-size: 13px;
        font-weight: 200;
        font-family: "azo-sans-web", sans-serif;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
        color: #71767D;
        min-width: 150px;
    }

    input[type=checkbox] {
        height: 30px;
        width: 20px;
        -webkit-clip-path: circle(56% at 50% 50%);
        clip-path: circle(56% at 50% 50%);
        accent-color: #1ba379;
    }
}

.selectFilterContainer {
    width: 250px;
    height: 40px;
    gap: 12px;
    display: flex;
    border-radius: 3px;
    border: 1px solid #e4e4e4;
    align-items: center;
    padding-left: 10px;
    position: absolute;
    right: 30px;
    top: 65px;
    background: #fff;

    .textarea {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        width: 72%;
        color: #7e7e7e;
    }
}

.actionIcon {
    width: 16px;
    height: 16px;
}

.actionsContainer {
    border-radius: 12px;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.031372549), 0px 12px 16px -4px rgba(16, 24, 40, 0.0784313725);
    background: #fff;
    position: absolute;
    color: #000000;
    top: 65px;
    right: 45px;
    min-height: 50px;
    width: 230px;
    font-weight: 600;
    font-size: 12px;
    
    .listConatiner {
        border: 1px solid #eaecf0;
        border-radius: 12px;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.031372549), 0px 12px 16px -4px rgba(16, 24, 40, 0.0784313725);
        background: #fff;

        .listViewAction {
            height: 35px;
            align-items: center;
            display: flex;
            font-size: 12px;
            padding: 0px 12px;
            margin: 5px;
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            color:#344054;
            gap:8px;
            border-radius: 5px;
            cursor: pointer;
            

            .viewsActionIcons {
                font-size: 20px;
            }

            &:hover {
                background-color: #F9FAFB;
                // color: #7556D9;
            }
        }
    }

    .statTesting {
        display: flex;
        height: 50px;
        align-items: center;
        padding: 0px 12px;
        font-family: "azo-sans-web", sans-serif;
        color: #344054;

        .statTestingContent {
            padding: 0px 10px 10px 17px;
            color: rgb(71, 84, 103);
            line-height: 20px;
            font-size: 11px;
        }
    }

    .buttonText {
        width: auto;
        display: flex;
        align-items: center;
        padding: 8px 10px;
        cursor: pointer;

        &.active {
            color: #5D5FEF;
        }

        &:hover {
            color: #5D5FEF;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.viewsActionContainer {
    display: flex;
    position: fixed;
    bottom: 2px;
    z-index: 999;
    margin-left: 30px;

    .content {
        background-color: green;
        padding: 10px;
        font-family: "Inter",serif;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        font-size: 15px;

        & .delete {
            color: red;
        }

        .icon {
            cursor: pointer;
            width: 22px;
            padding-left: 5px;
        }

        .action-btn {
            height: 30px;
            width: 30px;
            border-radius: 20px;
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ffffff;
            cursor: pointer;

            .icons {
                color: #fff;
            }
        }
    }
}

.selectContainer {
    input[type=checkbox] {
        height: 17px;
        width: 20px;
        accent-color: #1ba379;
        margin-top: 9px;
        clip-path: unset;
    }
}

.checkBoxContainer {
    padding: 10px 0px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;

    .viewFormTitle {
        font-size: 14px;
        color: #344054;
        font-weight: 500;
        padding-bottom: 2px;
        font-family: "Inter", sans-serif;
    }

    .viewFormSubTitle {
        font-size: 10px;
        font-family: 'Inter', sans-serif;
        color: #475467;
        letter-spacing: 0.002rem;
    }

    .viewCheckbox {
        width: 20px !important;
        height: 20px !important;
        border: 2px solid #3498db;
        /* Border color */
        cursor: pointer;
        border-radius: 5px;
    }
}

.view-card-more-option-modal.react-responsive-modal-modal {
    max-width: 400px !important;
    width: 400px;
    padding: 10px 20px;
    max-height: initial !important;

    .react-responsive-modal-closeButton {
        top: 24px !important;
        right: 14px;
        height: 24px;
        width: 24px;

        svg {
            fill: #98A2B3;
        }
    }

    .view-card-modal-header {
        height: 84px;
        display: flex;
        align-items: center;
        gap: 10px;

        .view-card-header-icon {
            background: #F4EBFF;
            padding: 9px;
            display: flex;
            border-radius: 50%;

            img {
                height: 20px;
                width: 20px;
            }
        }

        .view-card-modal-title {
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 2px;
        }

        .view-card-modal-subtitle {
            font-size: 13px;
        }
    }

    .view-card-modal-body {
        min-height: 72px;
    }

    .view-card-modal-footer {
        height: 72px;
        display: flex;
        align-items: center;
    }
}