.blueButton {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #0062FF;
  color: #FFFFFF;
  font-family: "Silka-Bold", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease-out 0s;

  &:hover {
    background-color: #0062FF;
    box-shadow: 0 2px 12px 0 rgba(0, 98, 255, 0.5);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #1F68DC;
    pointer-events: none;
    border: 1px solid #0062FF;

    &:hover {
      background-color: #0062FF;
      box-shadow: none;

    }
  }
}

.redButton {
  width: calc(100% - 40px);
  height: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #E51E00;
  color: #FFFFFF;
  font-family: "Silka-Bold", sans-serif;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;

  &:hover {
    background-color: #D92D20;
    box-shadow: 0 2px 12px 0 rgba(255, 0, 85, 0.5);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: #E51E00;
      box-shadow: none;
      ;
    }
  }
}

.whiteButton {
  width: calc(100% - 40px);
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #B5B9BF;
  border-radius: 2px;
  background-color: #FFFFFF;
  color: #344054;
  font-family: "Silka-Bold", sans-serif;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: #FFFFFF;
      box-shadow: none;
      ;
    }
  }

  &:hover {
    color: #ffffff;
    background-color: #B5B9BF;
  }
}

.borderButton {
  box-sizing: border-box;
  border: 1px solid #71767D;
  border-radius: 4px;
  background-color: transparent;
  color: #DFE2EA;
  font-family: "Silka-Bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  padding: 12px 20px;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: #DFE2EA;
      box-shadow: none;
      ;
    }
  }

  &:hover {
    background-color: #71767D;
  }
}

.blackButton {
  width: 185px;
  height: 46px;
  background-color: #000000;
  box-sizing: border-box;
  border-radius: 29px;
  color: #FFFFFF;
  font-family: 'Silka-Bold', sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 14px;
  padding: 16px 30px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: #000000;
      box-shadow: none;
      ;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  }
}

.orangeButton {
  width: calc(100% - 40px);
  height: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f16e4e;
  color: #FFFFFF;
  font-family: "Silka-Bold", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;

  &:hover {
    background-color: #f16e4e;
    box-shadow: 0 2px 12px 0 rgba(241, 110, 78, 0.5);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: #f16e4e;
      box-shadow: none;
      ;
    }
  }
}

.greyButton {
  width: calc(100% - 40px);
  height: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #696969;
  color: #FFFFFF;
  font-family: "Silka-Bold", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;

  &:hover {
    background-color: #696969;
    box-shadow: 0 2px 12px 0 rgba(241, 110, 78, 0.5);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: #696969;
      box-shadow: none;
      ;
    }
  }
}

.textButton {
  width: auto;
  height: 46px;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 29px;
  color: #FFFFFF;
  font-family: 'Silka-Bold', sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 14px;
  padding: 16px 30px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background-color: transparent;
      box-shadow: none;
      ;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  }
}

.blueTextButton {
  width: auto;
  height: 30px;
  background-color: transparent;
  box-sizing: border-box;
  color: #0062FF;
  font-family: "azo-sans-web", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    color: #0062FF;
  }
}

.roundedLogo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding-right: 10px;
}

.roundedTextLogo {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #292d33;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltipTextContainer {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0%;
  text-align: center;
  border-radius: 6px;
  padding: 4px 6px;
  height: 22px;
  z-index: 1;
  white-space: nowrap;
  border: 1px solid #0c0c0c;
  font-family: "Inter", sans-serif;
  box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 22px;
}

.tippy-content{
  padding: 0px;
}

input,
.surveyDropdownInput {
  background-color: transparent;
  border-radius: 0;
  outline: none;
  color: #333333;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

#drift-widget-container {
  z-index: 1 !important;
}

.inputBox {
  box-sizing: border-box;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  background-color: transparent;
  position: relative;

  &:focus-within {
    border: 1px solid #0062FF;
    box-shadow: none;
  }

  input,
  .surveyDropdownInput {
    width: 100%;
    height: 20px;
    box-sizing: border-box;
    padding: 0 15px;
    font-size: 12px;

    &:focus {
      border-bottom: none;
      box-shadow: none;
    }

    &::placeholder {
      color: #000000;
      opacity: 0.5;
    }
  }

  &.dateInput {
    display: flex;
    width: 80px;
    height: 50px;
    box-sizing: border-box;
    padding: 14px 0;
    // margin: 0 10px;
    position: relative;
    border: 1px solid #dfe2ea;
    border-radius: 3px;

    &:focus-within {
      border: 1px solid #0062FF;
      box-shadow: none;
    }

    >span {
      display: block;
      box-sizing: border-box;
      margin: 0 auto;
      height: 20px;
      font-weight: 500;
      font-size: 12px;
      color: #0062FF;

      @media screen and (max-width: 480px) {
        font-size: 12px !important;
      }
    }

    .react-date-picker.dateInput_picker {
      width: calc(100% - 15px);
      height: 100%;

      .react-date-picker__wrapper {
        border: none;

        .react-date-picker__inputGroup {
          min-width: none;
          margin-left: 10px;
        }
      }

      input {
        font-family: "azo-sans-web", sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        padding: 0;
      }

      .react-date-picker__button {
        padding: 0;
      }
    }
  }
}

.modalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 6000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modalContent {
    position: relative;
    width: 640px;
    height: 600px;
    box-sizing: border-box;
    padding: 80px 65px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: none;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    overflow: auto;
    overflow-x: hidden;
    text-align: center;

    .content {
      width: 100%;
      margin: 30px auto;
      border-top: 1px solid #DFE2EA;
      padding-top: 30px;

      h2 {
        color: #000000;
        font-family: 'Silka-bold', sans-serif;
        font-size: 32px;
        letter-spacing: -1px;
        line-height: 40px;
        text-align: center;
        margin: 0;
      }

      p {
        color: #aaa;
        font-family: "azo-sans-web", sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        padding: 5px 0 25px;
        margin: 0;

        span {
          color: #000;
        }
      }

      .email-link {
        font-family: "azo-sans-web", sans-serif !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        text-decoration: none;
      }
    }

    .message-block {
      font-family: "azo-sans-web", sans-serif !important;
    }
  }
}

.modalLayer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  >div {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 640px;
    height: fit-content;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
    border-radius: 4px;
    background: #fff;

    h1 {
      width: 100%;
      color: #000000;
      background-color: #ffffff;
      font-family: "Silka-Bold", sans-serif;
      font-size: 26px;
      letter-spacing: -0.5px;
      line-height: 28px;
      text-align: center;
      padding: 40px 0;
      border-bottom: 2px solid #DFE2EA;
      position: sticky;
      top: 10px;
      z-index: 1;
    }

    p {
      color: #71767D;
      font-family: "azo-sans-web", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 30px 0 0;
    }

    .resultInfo {
      margin: 20px auto 40px;
      text-align: center;
      color: #000000;
      font-family: "azo-sans-web", sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      span {
        display: block;
        font-weight: 400;
      }

      >div {
        width: 80%;
        margin: 0 auto;
        text-align: center;

        >span {
          display: inline;
          padding-right: 10px;
        }
      }
    }

    .newContentContainer {
      box-sizing: border-box;
      // overflow-y: visible;
      padding: 0 40px;
      margin: 0 0 0 -20px;

      .selectInputContainer {
        width: 100% !important;
      }
    }

    .label {
      color: #0062FF;
      font-family: "Silka-Bold", sans-serif;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 14px;
      padding: 30px 0 20px;
    }

    .inputColumn {
      display: flex;
      justify-content: space-between;
      ;

      >.inputBox {
        width: calc(50% - 5px);
      }
    }

    .label+.selectInputContainer {
      margin: 0 auto 20px;
    }

    .userListContainer {
      border-radius: 0 0 4px 4px;
      background-color: #FFFFFF;
      border: 1px solid #DFE2EA;
      padding: 0;
      margin: -40px auto 0;
      width: calc(100% - 80px);
      max-height: 30vh;
      overflow-y: auto;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;

      li {
        background-color: #ffffff;
        padding: 12px 40px;
        cursor: pointer;
        width: 100%;
        height: 54px;
        box-sizing: border-box;

        &:hover {
          background-color: #F2F4F7;
        }
      }
    }

    .brandItemListContainer {
      position: absolute;
      margin-top: -13px;
      z-index: 1;
      overflow-y: auto;
      width: calc(100% - 80px);
      max-height: 30vh;
    }

    .brandTitle {
      padding: 10px 0;

      &.searchSelected {
        cursor: pointer;

        .removeIcon {
          display: none;
        }

        &:hover {
          .removeIcon {
            display: inline-block;
            vertical-align: middle;
            margin-left: 30px;
          }
        }
      }
    }

    .selectInputContainer {
      width: calc(100% - 40px);
      margin: 20px auto 40px;
      border: 1px solid #DFE2EA;
      border-radius: 4px 4px 0 0;
      background-color: #FFFFFF;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: flex-start;
      align-items: center; // flex-direction: column;

      .searchIconContainer {
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .prefixIcon {
          height: 20px;
        }
      }

      input {
        border: none;
        width: 100%;
        height: 20px;
        font-size: 16px;
        line-height: 20px;
        padding: 20px 0;
        font-family: "azo-sans-web", sans-serif;
        font-weight: 400;
      }
    }

    .bottomButtons {
      display: flex;
      justify-content: center;
      position: sticky;
      bottom: 0;
      background-color: #ffffff;
      padding: 20px 0 30px;

      .button {
        margin: 0 5px;
      }
    }

    .deleteSegment {
      >.filterList {
        justify-content: space-evenly;
      }
    }
  }
}

.tooltip {
  position: absolute;
  display: none;
  box-sizing: border-box;
  padding: 14px 10px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
  z-index: 10;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  >div {
    text-align: center;
    // width: 160px;

    &.brand {
      width: 100%;
      color: #000000;
      font-family: 'Silka-SemiBold', sans-serif;
      font-size: 13px;
      line-height: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #DFE2EA;

      &.single {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    &.value {
      color: #000000;
      font-family: "azo-sans-web", sans-serif;
      font-size: 17px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 4px;
    }

    &.label {
      color: #71767D;
      font-family: 'Silka-Bold', sans-serif;
      font-size: 0.675rem;
      letter-spacing: 0.125rem;
      line-height: 18px;
      padding: 14px 0 0;
    }

    &.percentage {
      font-family: "azo-sans-web", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-top: 4px;
    }

    &.sampleSize {
      font-family: "azo-sans-web", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 4px;
    }

    &.date {
      font-family: "azo-sans-web", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 4px;
    }
  }
}

/*
.oval {
  display: none!important;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
  &:hover {
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2)
  }
}*/

.withQText {
  position: relative;
  cursor: pointer;

  >span {
    position: absolute;
    display: none;
    box-sizing: border-box;
    padding: 14px 10px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    z-index: 10;
    color: #71767D;
    font-family: "azo-sans-web", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    width: 180px;
  }

  &:hover>span {
    display: block;
  }
}

.link-btn {
  color: #0062FF !important;
  font-family: "azo-sans-web", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  padding: 0 4px;
}

.bottom-buttons {
  position: absolute;
  bottom: 10px;
  width: 90% !important;
}

.form-button-actions {
  display: flex;
  justify-content: space-between;
  width: 98%;
  gap: 10px;
  display: flex;
  padding: 0.8rem 0.3rem 0.3rem 0.3rem;
  position: static;
  bottom: 10px;
  font-family: "Inter", serif;

  .copyButton {
    cursor: pointer;
    display: flex;
    border: none;
    height: 45px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 13px;
    gap: 10px;
    width: 40%;
    color: #475467;
    font-weight: 600;
    letter-spacing: -0.5px;
    border-radius: 3px;

    &:hover {
      background-color: #f2f4f7;
      box-shadow: 0 0px 1px 0 #c9c4c4;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }

  .saveButton {
    width: 50%;
    cursor: pointer;
    display: flex;
    background-color: #1f68dc;
    color: #FFFFFF;
    height: 45px;
    width: 56%;
    border: none;
    font-size: 13px;
    border-radius: 3px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    border: 1px solid #1f68dc;
    box-shadow: 0px 1px 2px 0px #1018280D;

    &:hover {
      background-color: #0062FF;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }

  .deleteButton {
    width: 50%;
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    background: #d92d20;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    box-shadow: 0px 1px 2px 0px #1018280D;

  }

  .cancelButton {
    width: 50%;
    height: 45px;
    padding: 10px 15px;
    border: 1px solid #d0d5dd;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    border-radius: 3px;
    color: #344054;
    margin: 0px;
    box-shadow: 0px 1px 2px 0px #1018280D;

    &:hover {
      background-color: #f2f4f7;
    }

  }
}