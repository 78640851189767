.horizontalBarContainer {
    position: sticky;
    width: 100%;
    min-height: 55px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
    justify-content: space-between;
    border-bottom: 1px solid #E2E4E9;
    margin: 0;
    z-index: 6;

    &.prePost {
        overflow: visible;
        min-height: 80px;
        height: auto;
    }

    .horizontalBarBottom {
        display: block;
        position: absolute;
        top: 80px;
        width: 100%;
        height: 1px;
    }

    .menuBarContainer {
        box-sizing: border-box;
        transition: .5s ease-in-out;
    }

    .filterIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        //width: 5.75rem;
        // margin-right: 1.5rem;
        height: 38px;
        width: 38px;
        box-sizing: border-box;
        padding: 0;
        cursor: pointer;
        border-radius: 10px;

        &:hover {
            border: 1px solid #6941C6;
            background: #7F56D91A;
        }

        .filterIcon {
            width: 1.375rem;
            height: 1.5rem;
            vertical-align: middle;
            border-radius: 10px;

            &:hover {
                background: #7F56D91A;
                border: 1px solid #6941C6;
            }
        }

        .MuiBadge-anchorOriginBottomRightCircle.MuiBadge-invisible {
            transform: scale(1) translate(50%, 50%) !important;
        }

        .MuiBadge-anchorOriginBottomRightCircle {
            bottom: 80%;
        }

        .MuiBadge-badge {
            height: 12px;
            min-width: 12px;
        }
    }


    .toggleContainer {
        display: flex;
        flex-direction: row;
        // justify-content: space-around;
        // align-items: center;
        height: 50px;
        width: 140px;
        border-radius: 25px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-out 0s;

        &:hover {
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2)
        }

        .toggleIconContainer {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .rightBorder {
            border-right: 1px solid #DFE2EA;
        }
    }

    .toggleContainerHide {
        display: none;
    }

    .adtext:hover .tooltiptext {
        opacity: 1;
        visibility: visible;
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
        white-space: nowrap;
    }

    .adtext .tooltiptext {
        visibility: hidden;
        min-width: 100px;
        background-color: #edeff5;
        color: #4c4d4e;
        text-align: center;
        border-radius: 6px;
        padding: 4px 9px;
        height: 23px;
        position: absolute;
        z-index: 1;
        right: 30%;
        top: 80%;
        font-size: 12px;
        font-weight: 200;
        font-family: "azo-sans-web", sans-serif;
        padding-top: 5px;
        white-space: nowrap;
    }

    .adtext {
        font-size: 12px;
        color: #E51E00;
        font-family: "azo-sans-web", sans-serif;
        align-items: center;
        padding-left: 3px;
        overflow: hidden;
        float: right;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .exportContainer {
        display: flex;
        width: 38px;
        box-sizing: border-box;
        cursor: pointer;
        .exportBtnContainer{
            position: relative;
            display: flex;
            height: 39px;
            width: 38px;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            &:hover {
                border: 1px solid #6941C6;
                background: #7F56D91A;
            }
        }

        &.marginLeftAuto {
            margin-left: auto;
        }

        &.paddingRight {
            // padding-right: 6px;
        }
    }

    &.flexColumn {
        display: flex;
        flex-direction: column;
    }
}

.menuBarContainerWrapper {
    display: flex;
    width: 100%;
    gap: 20px;
    position: relative;
    align-items: center;

    .horizontalBarItemsContainer {
        gap: 15px;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 42px;
        padding: 0px 15px 0px 30px;
    }

    .horizontalBarActions {
        min-height: 38px;
        gap: 8px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        position: relative;
        right: 10px;
        display: flex;
        justify-content: flex-end;
    }
}

.pageTitleContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 37px;

    .primaryTiltle {
        color: #000000;
        font-family: 'Silka-SemiBold';
        font-size: 17px;
        line-height: 20px;
        height: 19px;
    }

    .secondaryTitle {
        color: #667185;
        font-family: "Inter", sans-serif;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 20px;
        height: 16px;
    }
}

.viewsHorizontalBar {
    position: absolute;
    display: flex;
    align-items: center;
    color: #7e7e7e;
    gap: 18px;
    right: 30px;

    .form-button-actions {
        width: 120px;
        height: 40px;
        padding: 0px;

        .saveButton {
            width: 120px;
            height: 40px;
        }
    }

    .actionIconsContainer {
        width: 20px;
        height: 20px;
        border-radius: 10px;

        .actionIcon {
            width: 16px;
            height: 16px;
        }

        &:hover {
            border: 1px solid #6941C6;
            background: #7F56D91A;
        }
    }
}

.newRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 57px;
    background-color: #F5F7FA;

    //position: absolute;
    // z-index: 10000;
    //top: 150px;
    // z-index: 3;
    .filterTab {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #DFE2EA;
        border-bottom: 1px solid #DFE2EA;
        height: 50px;
        min-width: 146px;
        border-radius: 31px;
        background-color: #F0F2F6;

        .selectListContainer {
            width: 130px;
            background-color: #F0F2F6;
            border: none;
        }

        img {
            top: 20px;
        }
    }
}

.grid {
    display: grid;
}

.floatRight {
    float: right;
}

.left {
    flex: 0;
}

.exportFilterBtn {
    flex: 0 0;
}

.warningText {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.sample {
    flex: 0.5 1;
}

.right {
    flex: 1;
}

.flex-container {
    display: flex;
    align-Items: center
}

.iconWarning {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.viewIconContainer {
    width: 22px;
    height: 22px;
    position: relative;
}

.iconInformation,
.viewIcon {
    cursor: pointer;
    border-radius: 5px;
}

.iconInformation {
    font-size: 25px;
}

.selectListContainer {
    margin-top: 30px;
    position: absolute;
    width: 345px;
    border: 1px solid #DFE2EA;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
    z-index: 100;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;

    .selectCampaignContainer {
        display: flex;
        border-bottom: 1px solid #DFE2EA;

        &:last-of-type {
            border: none;
        }

        .campaignNameBox {
            width: 50%;
            padding: 10px;
            background-color: #F2F3F7;
            border-right: 1px solid #DFE2EA;
            font-size: 14px;
            font-weight: 600;
            text-align: left;
            font-family: 'Silka-SemiBold';
        }

        .campaignRegionBox {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: 10px;
            background-color: #FFFFFF;
            font-size: 14px;
            color: #4B4D53;
            text-align: left;

            .campaignRegion {
                word-wrap: break-word;

                &:hover {
                    color: #000000;
                }
            }

            .selected {
                color: #0062FF;
            }
        }
    }
}

.warninglabel {
    font-size: 13px;
    width: 100%;
    padding: 10px 5px 10px 5px;
    display: block;
    background-color: #ffffff;
    border-bottom: 1px solid #DFE2EA;
}

@mixin periodContainerWithBorder {
    width: 100%;
    // border-top: 1px solid #DFE2EA;
}

.periodContainerWithBorder {
    @include periodContainerWithBorder;
}

.detailedPage {
    @media only screen and (max-width: 1200px) {
        .periodContainer {
            @include periodContainerWithBorder;
        }
    }

    @media only screen and (max-width: 1450px) {
        .filterIconContainer.filterOpen~.periodContainer {
            @include periodContainerWithBorder;
        }
    }
}

.competitivePage,
.advancedPage {
    @media only screen and (max-width: 950px) {
        .periodContainer {
            @include periodContainerWithBorder;
        }
    }

    @media only screen and (max-width: 1250px) {
        .filterIconContainer.filterOpen~.periodContainer {
            @include periodContainerWithBorder;
        }
    }

    @media screen and (max-width: 1400px) {
        .filterListContainer.listWithData~.periodContainer {
            @include periodContainerWithBorder;
        }
    }
}

.advancedPage {
    @media only screen and (max-width: 1500px) {
        .periodContainer {
            @include periodContainerWithBorder;
        }

        .periodContainer.customDates {
            @include periodContainerWithBorder;
        }

        .periodContainer.notCustomDates {
            @include periodContainerWithBorder;
        }
    }

    @media only screen and (max-width: 1700px) {
        .filterListContainer.listWithData~.periodContainer.customDates {
            @include periodContainerWithBorder;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .contentContainer.expand .periodContainer {
        @include periodContainerWithBorder;
    }
}

@media only screen and (max-width: 1600px) {
    .contentContainer.expand .filterListContainer.listWithData~.periodContainer {
        @include periodContainerWithBorder;
    }

    .filterListContainer.listWithData~.filterIconContainer.filterOpen~.periodContainer {
        @include periodContainerWithBorder;
    }
}

.report-builder-beta-tag {
    background-color: #E3EFFC;
    color: #04326B;
    font-size: 11px;
    font-weight: 500;
    padding: 3px 10px;
    border-radius: 12px;
    margin-left: 4px;
    position: relative;
    top: -2px;
    font-family: "Inter", sans-serif;
}