$standard-element-padding: 1rem 0.75rem 1rem 0.75rem;

.dropDown {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;

  &:hover {
    cursor: pointer;
  }

  .elementConatiner {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .marketLogo {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 15px;
  }

    .dropDownFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 90px;

        height: 27px;
        padding: $standard-element-padding;

        border-top: 1px solid #E2E2E2;
        background: white;
        box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);

    .selectText {
      font-family: "Inter", sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0;
      margin-top: 2px;
      color: #212529;
      align-items: center;
      justify-content: space-between;
      display: flex;
    }

    .buttonContainer {
      width: 125;
      height: 32;
      border-radius: 2px;
      gap: 12px;

    }

    .resetButton {
      width: 53px;
      height: 32px;
      border-radius: 3px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 2px 0px #1018280D;
        border: 0.2px solid #E2E2E2;
        background-color: #dad5d54a;
      }


    }

    .applyButton {
      width: 60px;
      height: 32px;
      border-radius: 3px;
      border-width: 1px;
      gap: 8px;
      padding-top: 10px;
      padding-right: 15px;
      padding-bottom: 10px;
      padding-left: 15px;
      background: #1F68DC;
      color: #fff;
      border: 1px solid #1F68DC;
      box-shadow: 0px 1px 2px 0px #1018280D;
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1.5;
      }
    }
  }

  .dropDownHeaderContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    img.ddArrow {
      transition: all 0.5s;
    }

    .multiSelected {
      display: flex;

      .selectedNum {
        box-sizing: border-box;
        height: 42px;
        width: 42px;
        border: 2px solid #EFF1F6;
        border-radius: 50%;
        font-family: 'Silka-SemiBold', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .selectedNum:hover .tooltiptext {
        visibility: visible;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: normal;
      }

      .selectedNum .tooltiptext {
        visibility: hidden;
        border-radius: 6px;
        padding: 5px 9px;
        position: absolute;
        z-index: 1;
        left: 15%;
        top: 100%;
        font-size: 13px;
        font-weight: 200;
        font-family: "azo-sans-web", sans-serif;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);
        color: #71767D;
      }

      .selectedListConatiner {
        height: 45px;
        min-width: 150px;
        display: flex;
        position: relative;

        .elementTitle {
          display: none;
        }

        .brandItem {
          position: absolute;
          padding: 0;

          .brandTitle {
            .brandName {
              display: none
            }
          }
        }

        .marketItem {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .marketTitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: transparent;
            position: absolute;

            .marketLogo {
              height: 40px;
              width: 40px;
              object-fit: cover;
              border-radius: 25px;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
              margin-right: 10px;
            }
          }
        }
      }
    }

    .campaignTitle {
      display: flex;
      height: 39px;
      flex-direction: column;
      justify-content: space-between;

      .campaignRegion {
        font-family: "Silka-SemiBold", sans-serif;
        font-size: 15px;
        height: 22px;
        line-height: 21.78px;
        color: #000000;
      }

      .campaignName {
        font-size: 12px;
        font-weight: 500;
        color: #0062FF;
        line-height: 19.94px;
        white-space: nowrap;
      }
    }

    .brandTitle {

      .brandLogoIcon,
      .brandDefaultLogo {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 25px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
        margin-right: 10px;
        border: '2px solid #fff';

        @media screen and (max-width: 812px) {
          height: 40px;
          width: 40px;
          border-radius: 20px;

        }
      }

      .brandLogoIcon {
        background-color: #ffffff;
      }

      .brandName {
        font-weight: 400;
        width: 100%;
        font-size: 15px;
        color: #000000;
        font-family: "Silka-SemiBold", sans-serif;
        overflow: hidden;
        line-height: 21.87px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: nowrap;

        @media screen and (max-width: 812px) {
          width: calc(100% - 50px);
          font-size: 18px;
          line-height: 22px;
          white-space: normal !important;
        }
      }

      .brandName:hover .tooltiptext {
        opacity: 1;
        visibility: visible;
        cursor: pointer;
        text-overflow: clip;
        word-break: break-all;
        white-space: nowrap;
      }

      .brandName .tooltiptext {
        visibility: hidden;
        min-width: 100px;
        background-color: #edeff5;
        color: #4c4d4e;
        text-align: center;
        border-radius: 6px;
        padding: 5px 9px;
        height: 25px;
        position: absolute;
        z-index: 1;
        left: 30%;
        top: 80%;
        font-size: 12px;
        font-weight: 200;
        font-family: "azo-sans-web", sans-serif;
        padding-top: 5px;
        white-space: nowrap;
        border-radius: 15px;
      }
    }
  }

  .dropDownContainer {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    min-width: 380px;
    border: 1px solid #F0F2F5;
    background-color: #ffffff;
    z-index: 20;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(23, 24, 26, 0.1);

    .dropDownHeader {
        position: sticky !important;
        height: 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: $standard-element-padding;

        border-bottom: 1px solid #E2E2E2;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

        .dropDownTitleContainer {
            display: flex;
            align-items: center;

            font-family: "Inter", sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0;
            color: #212529;
        }

        .actionIcon {
            width: 2rem;
            height: 2rem;

            display: flex;
            justify-content: center;
            align-items: center;

            transition: all .2s ease-in-out;

            &:hover {
                border-radius: 4px;
                background-color: #F2F4F7;
            }

            .svgIcon {
                width: 1.25rem;
                height: 1.25rem;
            }
        }

      .iconContainer {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .warningIcon {
        width: 17px;
        height: 17px;
        margin-right: 6px;
        color: orangered
      }
    }
  }

  .dropdownListContainer {
    display: none;
    box-sizing: border-box;
    background-color: #ffffff;
    min-height: 25px;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;

    .elementRoundedLogo {
      width: 33px;
      height: 33px;
      border-radius: 50px;
      border-width: 2px;
      border: 1px solid #ECECEC;
      display: flex;
      background: #F1F1F1;
      padding: 0px;
      align-items: center;
      justify-content: center;
    }

    >.selectItem {
      width: 100%;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: $standard-element-padding;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 0.75rem;

      .elementTitle {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
        color: #344054;
        padding-left: 5px;


      }


      &.selected {
        color: #0062FF;

        &:hover {
          background-color: transparent;
          transition: all .2s ease-in-out ;
        }
      }

      &:hover {
        background-color: #F2F4F7;
      }

      .icon {
        width: 33px;
        height: 33px;
        object-fit: cover;
        border-radius: 50%;

        &.textIcon {
          box-sizing: border-box;
          border: 2px solid #FFFFFF;
          background-color: #292D33;
          color: #DFE2EA;
          font-family: 'Silka-Bold', sans-serif;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .titleName {
        width: calc(100% - 45px);

        >.sSize {
          width: auto;
          font-family: 'azo-sans-web', sans-serif;
          font-weight: 400;
          font-size: 12px;
          color: #71767D;
        }
      }
    }

    .selectCampaignContainer {
      display: flex;
      border-bottom: 1px solid #DFE2EA;
      background-color: #DFE2EA;

      &:last-of-type {
        border: none;
      }

      .campaignNameBox {
        width: 50%;
        padding: 10px;
        background-color: #F2F3F7;
        border-right: 1px solid #DFE2EA;
        font-size: 14px;
        font-weight: 600;
        cursor: default;
      }

      .campaignRegionBox {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 10px;
        background-color: #FFFFFF;
        font-size: 14px;
        color: #4B4D53;

        .campaignRegion {
          word-wrap: break-word;

          &:hover {
            color: #000000;
          }
        }

        .selected {
          color: #0062FF;
        }
      }
    }
  }

  &.open {
    img.ddArrow {
      transform: rotate(-180deg);
      transition: all .3s ease-in-out;
    }

    .dropdownListContainer {
      display: block;
    }

    .dropDownContainer {
      display: block;
    }
  }

  input[type="checkbox"] {
    height: 20px;
    width: 20px;
    accent-color: #1BA379;
    clip-path: none !important;
    border: 1.5px solid #D0D5DD;
  }
}